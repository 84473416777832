/**
* Template Name: Selecao
* Template URL: https://bootstrapmade.com/selecao-bootstrap-template/
* Updated: Mar 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
  }
  
  a {
    color: #ef6603;
    text-decoration: none;
  }
  
  a:hover {
    color: #fc8129;
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Raleway", sans-serif;
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #AB9458;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: white;
    color: #AB9458;
    border: 1px solid #AB9458;
  }
  .back-to-top i:hover {
    color: #AB9458;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Disable AOS delay on mobile
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    height: 70px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    background: rgba(42, 44, 57, 0.9);
  }
  
  #header.header-transparent {
    background: rgba(4, 4, 6, 0.2);
  }
  
  #header.header-scrolled {
    background: rgba(42, 44, 57, 0.9);
  }
  
  #header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
  }
  
  #header .logo h1 a,
  #header .logo h1 a:hover {
    color: #fff;
    text-decoration: none;
  }
  
  #header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px 7px 15px;
    margin-left: 5px;
    font-size: 14px;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
    border-radius: 50px;
  }
  
  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    background: #AB9458;
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 5px;
    top: calc(100% + 30px);
    margin: 5px 0 0 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 15px;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    padding: 10px 20px;
    color: #2a2c39;
    margin: 0 5px;
    font-size: 14px;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: #fff;
  }
  
  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(20, 21, 28, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    margin: 5px;
    font-size: 15px;
    color: #2a2c39;
  }
  
  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: #fff;
  }
  
  .navbar-mobile .getstarted,
  .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    color: #fff;
  }
  
  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    overflow: hidden;
    position: relative;
    background: linear-gradient(0deg, rgb(42, 44, 57) 0%, #AB9458 100%), url('../public/assets/img/banner-home.jpg');
    background-size: cover;
    background-blend-mode: overlay;
    padding: 0;
  }
  
  #hero .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    height: 70vh;
    padding-top: 60px;
  }
  
  #hero h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero p {
    width: 80%;
    animation-delay: 0.4s;
    margin: 0 auto 30px auto;
    color: #fff;
  }
  
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 10%;
  }
  
  #hero .carousel-control-next-icon,
  #hero .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
  }
  
  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    animation-delay: 0.8s;
    border: 2px solid #AB9458;
  }
  
  #hero .btn-get-started:hover {
    background: #AB9458;
    color: #fff;
    text-decoration: none;
  }
  
  @media (min-width: 1024px) {
    #hero p {
      width: 60%;
    }
  
    #hero .carousel-control-prev,
    #hero .carousel-control-next {
      width: 5%;
    }
  }
  
  @media (max-width: 768px) {
    #hero .carousel-container {
      height: 90vh;
    }
  
    #hero h2 {
      font-size: 28px;
    }
  }
  
  .hero-waves {
    display: block;
    width: 100%;
    height: 60px;
    position: relative;
  }
  
  .wave1 use {
    animation: move-forever1 10s linear infinite;
    animation-delay: -2s;
  }
  
  .wave2 use {
    animation: move-forever2 8s linear infinite;
    animation-delay: -2s;
  }
  
  .wave3 use {
    animation: move-forever3 6s linear infinite;
    animation-delay: -2s;
  }
  
  @keyframes move-forever1 {
    0% {
      transform: translate(85px, 0%);
    }
  
    100% {
      transform: translate(-90px, 0%);
    }
  }
  
  @keyframes move-forever2 {
    0% {
      transform: translate(-90px, 0%);
    }
  
    100% {
      transform: translate(85px, 0%);
    }
  }
  
  @keyframes move-forever3 {
    0% {
      transform: translate(-90px, 0%);
    }
  
    100% {
      transform: translate(85px, 0%);
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-title {
    padding-bottom: 40px;
  }
  
  .section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: "Poppins", sans-serif;
  }
  
  .section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #AB9458;
    margin: 4px 10px;
  }
  
  .section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #05411B;
  }
  
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about {
    padding-top: 80px;
  }
  
  .about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .about .content ul li+li {
    margin-top: 10px;
  }
  
  .about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #05411B;
    line-height: 1;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .content .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #AB9458;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #AB9458;
  }
  
  .about .content .btn-learn-more:hover {
    background: #AB9458;
    color: #fff;
    text-decoration: none;
  }
  
  /*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
  .features .nav-tabs {
    border: 0;
  }
  
  .features .nav-link {
    border: 1px solid #d4d6df;
    padding: 15px;
    transition: 0.3s;
    color: #2a2c39;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .features .nav-link i {
    padding-right: 15px;
    font-size: 48px;
  }
  
  .features .nav-link h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  
  .features .nav-link:hover {
    color: #AB9458;
  }
  
  .features .nav-link.active {
    background: #AB9458;
    color: #fff;
    border-color: #AB9458;
    border-radius: 12px;
  }
  
  @media (max-width: 768px) {
    .features .nav-link i {
      padding: 0;
      line-height: 1;
      font-size: 36px;
    }
  }
  
  @media (max-width: 575px) {
    .features .nav-link {
      padding: 15px;
    }
  
    .features .nav-link i {
      font-size: 24px;
    }
  }
  
  .features .tab-content {
    margin-top: 30px;
  }
  
  .features .tab-pane h3 {
    font-weight: 600;
    font-size: 26px;
    color: #05411B;
  }
  
  .features .tab-pane ul {
    list-style: none;
    padding: 0;
  }
  
  .features .tab-pane ul li {
    padding-bottom: 10px;
  }
  
  .features .tab-pane ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #05411B;
  }
  
  .features .tab-pane p:last-child {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Cta
  --------------------------------------------------------------*/
  .cta {
    background: #05411B;
    padding: 80px 0;
  }
  
  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: #fff;
  }
  
  .cta .cta-btn {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    color: #fff;
    background: #AB9458;
  }
  
  .cta .cta-btn:hover {
    background: #fff;
    color: #05411B;
  }
  
  @media (max-width: 1024px) {
    .cta {
      background-attachment: scroll;
    }
  }
  
  @media (min-width: 769px) {
    .cta .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 2px 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.4s ease-in-out;
    width: 100%;
    height: 100%;
  }
  
  .services .icon-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 2px 35px 0 rgba(68, 88, 144, 0.2);
  }
  
  .services .icon {
    position: absolute;
    left: -20px;
    top: calc(50% - 30px);
  }
  
  .services .icon i {
    font-size: 64px;
    line-height: 1;
    transition: 0.5s;
  }
  
  .services .title {
    margin-left: 40px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .title a {
    color: #2a2c39;
    transition: ease-in-out 0.3s;
  }
  
  .services .title a:hover {
    color: #AB9458;
  }
  
  .services .description {
    font-size: 14px;
    margin-left: 40px;
    line-height: 24px;
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
  .portfolio #portfolio-flters {
    list-style: none;
    margin-bottom: 20px;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    margin: 0 0 10px 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    transition: all 0.3s ease-in-out;
  }
  
  .portfolio #portfolio-flters li::before {
    content: "[";
    margin-right: 6px;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
  }
  
  .portfolio #portfolio-flters li::after {
    content: "]";
    margin-left: 6px;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
  }
  
  .portfolio #portfolio-flters li:hover,
  .portfolio #portfolio-flters li.filter-active {
    color: #AB9458;
  }
  
  .portfolio #portfolio-flters li.filter-active::before,
  .portfolio #portfolio-flters li.filter-active::after {
    color: #05411B;
  }
  
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio .portfolio-item .portfolio-img {
    overflow: hidden;
  }
  
  .portfolio .portfolio-item .portfolio-img img {
    transition: all 0.8s ease-in-out;
  }
  
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 15px;
    bottom: 0;
    z-index: 3;
    right: 15px;
    transition: all ease-in-out 0.3s;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px 15px;
  }
  
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
  }
  
  .portfolio .portfolio-item .portfolio-info p {
    color: #fedac0;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link,
  .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #fff;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link:hover,
  .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: #fd9f5b;
  }
  
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 10px;
  }
  
  .portfolio .portfolio-item .portfolio-links {
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 3;
    position: absolute;
    transition: all ease-in-out 0.3s;
  }
  
  .portfolio .portfolio-item .portfolio-links a {
    color: #fff;
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-item .portfolio-links a:hover {
    color: #fd9f5b;
  }
  
  .portfolio .portfolio-item:hover .portfolio-img img {
    transform: scale(1.2);
  }
  
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
    padding-top: 40px;
  }
  
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #ef6603;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ef6603;
  }
  
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(42, 44, 57, 0.08);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }
  
  .testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px 30px 0 30px;
    margin: 30px 15px;
    text-align: center;
    min-height: 350px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  }
  
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: 0 auto;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left,
  .testimonials .testimonial-item .quote-icon-right {
    color: #fedac0;
    font-size: 26px;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
  }
  
  .testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #ef6603;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ef6603;
  }
  
  /*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
  .pricing .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }
  
  .pricing h3 {
    font-weight: 400;
    margin: -20px -20px 25px -20px;
    padding: 30px 15px;
    font-size: 18px;
    font-weight: 600;
    color: #777777;
    background: #f8f8f8;
  }
  
  .pricing h4 {
    font-size: 36px;
    color: #AB9458;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }
  
  .pricing h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }
  
  .pricing h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
  
  .pricing ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }
  
  .pricing ul li {
    padding-bottom: 16px;
  }
  
  .pricing ul i {
    color: #AB9458;
    font-size: 18px;
    padding-right: 4px;
  }
  
  .pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
  }
  
  .pricing .btn-wrap {
    margin: 20px -20px -20px -20px;
    padding: 20px 15px;
    background: #f8f8f8;
    text-align: center;
  }
  
  .pricing .btn-buy {
    background: #AB9458;
    display: inline-block;
    padding: 8px 35px 10px 35px;
    border-radius: 50px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    transition: 0.3s;
  }
  
  .pricing .btn-buy:hover {
    color:#AB9458;
    background: #FFF;
    border: 2px solid #AB9458;
  }
  
  .pricing .featured {
    border: 2px solid #AB9458;
  }
  
  .pricing .featured h3 {
    color: #fff;
    background: #AB9458;
  }
  
  .pricing .advanced {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: #AB9458;
    color: #fff;
  }
  
  /*--------------------------------------------------------------
  # F.A.Q
  --------------------------------------------------------------*/
  .faq {
    padding: 60px 0;
  }
  
  .faq .faq-list {
    padding: 0;
    list-style: none;
  }
  
  .faq .faq-list li {
    border-bottom: 1px solid #ececf1;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  
  .faq .faq-list .question {
    display: block;
    position: relative;
    font-family: #ef6603;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    padding-left: 25px;
    cursor: pointer;
    color: #AB9458;
    transition: 0.3s;
  }
  
  .faq .faq-list i {
    font-size: 16px;
    position: absolute;
    left: 0;
    top: -2px;
  }
  
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 25px;
    color: #05411B;
  }
  
  .faq .faq-list .icon-show {
    display: none;
  }
  
  .faq .faq-list .collapsed {
    color: black;
  }
  
  .faq .faq-list .collapsed:hover {
    color: #AB9458;
  }
  
  .faq .faq-list .collapsed .icon-show {
    display: inline-block;
    transition: 0.6s;
  }
  
  .faq .faq-list .collapsed .icon-close {
    display: none;
    transition: 0.6s;
  }
  
  /*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
  .team {
    background: #fff;
    padding: 60px 0;
  }
  
  .team .member {
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .team .member .member-img {
    position: relative;
    overflow: hidden;
  }
  
  .team .member .member-info {
    padding: 25px 15px;
  }
  
  .team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
    color: #2a2c39;
  }
  
  .team .member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
  }
  
  .team .member .member-info p {
    font-style: italic;
    font-size: 14px;
    line-height: 26px;
    color: #777777;
  }
  
  .team .member:hover .social {
    opacity: 1;
    bottom: 15px;
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info {
    width: 100%;
    background: #fff;
  }
  
  .contact .info i {
    font-size: 20px;
    color: #AB9458;
    float: left;
    width: 44px;
    height: 44px;
    background: #ffecde;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #2a2c39;
  }
  
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #555974;
  }
  
  .contact .info .email,
  .contact .info .phone {
    margin-top: 40px;
  }
  
  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
    background: #AB9458;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    background: #fff;
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #AB9458;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type=submit] {
    background: #AB9458;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: #FFF;
    border: 2px solid #AB9458;
    color: #AB9458;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    margin-top: 70px;
    background: #f7f8f9;
    min-height: 40px;
  }
  
  .breadcrumbs h2 {
    font-size: 28px;
    font-weight: 400;
    margin: 0;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  
  @media (max-width: 992px) {
    .breadcrumbs ol {
      margin-top: 10px;
    }
  }
  
  .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #404356;
    content: "/";
  }
  
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
  
    .breadcrumbs ol {
      display: block;
    }
  
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    background: #05411B;
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 30px 0;
  }
  
  #footer h3 {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
    position: relative;
    font-family: "Poppins", sans-serif;
    padding: 0;
    margin: 0 0 15px 0;
  }
  
  #footer p {
    font-size: 15;
    font-style: italic;
    padding: 0;
    margin: 0 0 40px 0;
  }
  
  #footer .social-links {
    margin: 0 0 40px 0;
  }
  
  #footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #AB9458;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .social-links a:hover {
    background: white;
    color: #AB9458;
    text-decoration: none;
  }
  
  #footer .copyright {
    margin: 0 0 5px 0;
  }
  
  #footer .credits {
    font-size: 13px;
  }
  
  .backcontact{
    background-image: url(../public/assets/img/FundConatct.jpg);
    background-position: top center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;

  }

  .backanuncio1{
    background-image: url(../public/assets/img/Activi_ortog.jpg);
    background-position: top center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;

  }
  .backanuncio2{
    background-image: url(../public/assets/img/Activi_feira01.jpg);
    background-position: top center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;

  }
  .backanuncio3{
    background-image: url(../public/assets/img/Activi_feira02.jpg);
    background-position: top center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;

  }

  .backEventos1{
    background-image: url(../public/assets/img/Ativi_Desport02.png);
    background-position: top center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;

  }

  .backEventos2{
    background-image: url(../public/assets/img/Dia_Arica06.png);
    background-position: top center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;

  }
  .backEventos3{
    background-image: url(../public/assets/img/esc03.jpg);
    background-position: top center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;

  }

  .backdestaque1{
    background-image: url(../public/assets/img/prem03.jpg);
    background-position: top center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;

  }

  .backdestaque2{
    background-image: url(../public/assets/img/pub02.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 700px;
    margin-left: 50px;

  }

  .backdestaque3{
    background-image: url(../public/assets/img/pub01.jpg);
    background-position: top center center;
    background-repeat: no-repeat;
    background-size: cover;

  }

  .portfolio-descriptionlab {
    width: 800px;
  }